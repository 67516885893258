import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { TablePagination, Typography } from '@mui/material';
import ActionMenu, { ItemAction } from '../common/ActionMenu';
import { ProjectPaymentLimit } from '@/app/models/projectPaymentLimit';
import StandardDialog from '../common/StandardDialog';
import { useState } from 'react';

const HeaderTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  fontSize: 13,
  fontWeight: 'bold',
  paddingBottom: 1,
}));

const RowTableCell = styled(TableCell)({
  fontSize: 13,
});

type ProjectExceptionsTableProps = {
  rows: Array<ProjectPaymentLimit>;
  setEditProject: (s: string) => void;
  setShowCreateModal: (b: boolean) => void;
  handleDeleteAll: (s: string) => void;
  count: number;
  page: number;
  rowsPerPage: number;

  setPage: (n: number) => void;
  setRowsPerPage: (n: number) => void;
};

function ProjectExceptionsTable(props: ProjectExceptionsTableProps) {
  const {
    rows,
    setEditProject,
    setShowCreateModal,
    handleDeleteAll,
    count,
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
  } = props;
  const { t } = useTranslation('exceptions');

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteAllCode, setDeleteAllCode] = useState(null as unknown as string);
  return (
    <>
      <StandardDialog
        title={t('Sincronizar')}
        contentText={`${t('Tem certeza que deseja excluir todos casos de exceção desse projeto')}`}
        open={showDeleteDialog}
        onClose={() => {
          setShowDeleteDialog(false);
        }}
        onConfirm={() => {
          setShowDeleteDialog(false);
          handleDeleteAll(deleteAllCode);
        }}
        cancelText={t('Não')}
        confirmText={t('Sim')}
      />
      <TableContainer>
        <Table size="small" aria-label="Exception Case Table">
          <TableHead>
            <TableRow>
              <HeaderTableCell>{t('Projeto Pagador')}</HeaderTableCell>
              <HeaderTableCell>{t('Projetos Destino')}</HeaderTableCell>
              <HeaderTableCell>{t('Ações')}</HeaderTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.payingProject.code}>
                <RowTableCell>{`${row.payingProject.code} - ${row.payingProject.name}`}</RowTableCell>
                <RowTableCell>{row.projects.toString()}</RowTableCell>
                <RowTableCell>
                  <ActionMenu>
                    <ItemAction
                      text={t('Editar Regra')}
                      Icon={EditIcon}
                      onClick={() => {
                        setEditProject(row.payingProject.code);
                        setShowCreateModal(true);
                      }}
                    />
                    <ItemAction
                      text={t('Excluir Regra')}
                      Icon={DeleteIcon}
                      onClick={() => {
                        setDeleteAllCode(row.payingProject.code);
                        setShowDeleteDialog(true);
                      }}
                    />
                  </ActionMenu>
                </RowTableCell>
              </TableRow>
            ))}
            {rows.length === 0 && (
              <TableRow>
                <TableCell sx={{ textAlign: 'center' }} colSpan={8}>
                  <Typography variant="subtitle2">{t('Ainda não há casos de exceção adicionados')}</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          sx={{ width: '100%' }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={count || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, p) => setPage(p)}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(parseInt(e.target.value, 10));
            setPage(0);
          }}
          labelDisplayedRows={({ from, to, count: limit }) => `${from}-${to} ${t('de')} ${limit}`}
          labelRowsPerPage={t('itens por página')}
        />
      </TableContainer>
    </>
  );
}

export default ProjectExceptionsTable;
